// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
    jQuery(".menu-toggle").on("click", function () {
        jQuery("body").toggleClass("menu-open");
    });

    jQuery(document).on("click", function () {
        jQuery("body").removeClass("menu-open");
    });
    jQuery(".main-navigation, .menu-toggle").on("click", function (e) {
        e.stopPropagation();
    });
    jQuery(".your-class").slick({
        autoplay: true,
        autoplaySpeed: 6000,
    });

    jQuery(".phone").text(function (i, text) {
        text = text.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2-$3");
        return text;
    });
    jQuery(".fax-no").text(function (i, text) {
        text = text.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2-$3");
        return text;
    });
    jQuery(".contact-nuber").text(function (i, text) {
        text = text.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "$1-$2-$3");
        return text;
    });
    jQuery(".services-contact").text(function (i, text) {
        text = text.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "$1-$2-$3");
        return text;
    });
    jQuery(".locksmith").text(function (i, text) {
        text = text.replace(/(\d\d\d)(\d\d\d\d\d\d)/, "$1-$2");
        return text;
    });
});
